import { SitePage } from 'app/layout';
import { Box, Container } from '@mui/material';
import not_found from 'images/not-found.png';

export default function notFound() {
  return (
    <SitePage>
      <Box
        sx={{
          display: 'flex',
          height: '80%',
          justifyContent: 'center',
          alignItems: 'center',
          '& > img': { width: '80%' },
        }}>
        <img src={not_found} />
      </Box>
    </SitePage>
  );
}
